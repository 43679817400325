import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          nickkoldys.com version 2 coming soon.
        </p>
        <p><a href="https://nickkoldys.com">version 1</a></p>
      </header>
    </div>
  );
}

export default App;
